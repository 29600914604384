import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Container, Box, Grid, Paper, Typography, TextField, Button, Divider, Tooltip} from '@material-ui/core'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { User, Permissions } from 'react-frontend-utils' 
import { PP } from '../models/PP'
import { ThemeColors } from '../Theme'
import { RestComponent, Email, PopupMenu } from 'react-frontend-utils' 
import { OpenInNewTab } from '../App'
import { PermissionCheckboxes } from '../components/PermissionCheckboxes'

/**
 *  The EditUser Page provides allows editing of a registered user. It may be called in one of two ways;
 *  
 *  1. By the logged in user selecting the "Profile" menu option, to edit their own information
 *  2. By an administrator editing a user from the ManageUser page
 *  
 *  The parent component MUST pass in a prop called userToEdit which is a User object containing the user to edit.
 *  The parent can pass in a prop called closePage which is a function to call when the Back button is pressed
 *  The parent can pass in a prop called selfDidUpdate which is a function to call if the User updated themselves
 *  
 *  The truth table for editing a user based on who is logged in is as follows
 *  
 *   Logged in User -->       Admin       Manager       Attd/Employee      Patron    
 *   
 *  User to Edit ▼
 *   
 *      Admin              Profile/PW        n/a           n/a              n/a
 *      
 *      Manager                All        Profile/PW       n/a              n/a
 *      
 *      Attd/Employee          All           n/a         Read Only          n/a
 *      
 *      Patron/Eval            All           n/a            n/a             n/a
 *   
 * 
 */

const MAX_NAME_LEN = 50;  //for user attribute fields

export class EditUserPage extends RestComponent {
  
    
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        textfield: {
            marginBottom: 20
        },
        paperLabel: {
            marginLeft: 15,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        role: {
            marginTop: 10,
            marginLeft: 15,
            marginBottom: 15,
            fontSize: '12pt',
            flexGrow: 1
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        button: {
            maxWidth: '100%'        
        }
        
    }
  
  
    _isNew = false;
    _isSelf = true;             //true if the logged in user is the same as the one being edited
    _canChangeRole = false;     //true when role can be changed
    _isReadOnly = false;        //true if all the information is read only
    _canDelete = false;         //true if the user can be deleted
    _closePageCallback = null;  //callback when the Back button is pressed
    _selfDidUpdate = null;      //callback when the user edited themselves
    _canSendTestEmail = false;   //True only if the user is a superadmin
    _isSelfProfile = false;
    
    constructor(props) {
        super(props);
        this.state.user = props.userToEdit;
        
        this._closePageCallback = props.closePage;
        this._selfDidUpdate = props.selfDidUpdate;
        this._isSelfProfile = props.isSelfProfile;
        
        this._isNew = !this.state.user.id;   //if no id yet, user is new
        
        this._isSelf = (PP.user.id === this.state.user.id);   //see if you're the same as the user to edit
        
        const userName = this._isNew ? "new user" : this.state.user.name();
        console.log("Editing " + userName + (this._isSelf ? " (myself)" : "") + " id: " + this.state.user.id + ", role: \"" + this.state.user.role + "\", available databases are: " + this.state.user.databases.toString());
        console.log(this.state.user.name() + " has these permissions: " + JSON.stringify(this.state.user.getPermissions()));
           
        const canCreateUser = PP.user.isAdministrator() || PP.user.hasPermissionTo(Permissions.CREATE_USERS);
           
        // only admins or employees with permission can change roles, to start with
        this._canChangeRole = canCreateUser;
       
        if (this._isSelf)   
            this._canChangeRole = false;    // if we're ourselves, we can't change our own role
        
        if (this.state.user.isAdministrator() && !PP.user.hasPermissionTo(Permissions.CREATE_USERS))
            this._canChangeRole = false;    // we can't change roles of administrators if we're not employees with permission
        
        if (this.state.user.isSuperAdmin())
            this._canChangeRole = false;    // we can't change superadmins at all
        
        if (this.state.user.isSiteEmployee() && !PP.user.isSuperAdmin())
            this._canChangeRole = false;    // we can't change employee roles unless we're a superadmin (but admins can't see employees anyway so this is just a sanity check)
     

        //Read only when user can't create
        this._isReadOnly = !canCreateUser;
        
        //Can change databases only if you can also change their role
        this._canChangeDatabases = this._canChangeRole;
        
        //Can delete this user when its not yourself, and you are an administrator
        this._canDelete = (!this._isSelf) && canCreateUser;
        
        this._canSendTestEmail = PP.user.isSuperAdmin();
    }

    /**
     * Changes the user's role to the next one lower, or cycle back to the top
     */
    _cycleRole = () => {
        
        const maxRole = User.Role.roleBelow(PP.user.role);

        switch (this.state.user.role) {
            case User.Role.AGENT:
                this.state.user.role = User.Role.EMPLOYEE;
                break;
            case User.Role.EMPLOYEE:
                this.state.user.role = User.Role.ADMIN;
                break;
            case User.Role.ADMIN:
                this.state.user.role = User.Role.MANAGER;
                break;
            case User.Role.MANAGER:
                this.state.user.role = User.Role.ATTENDANT;
                break;
            case User.Role.ATTENDANT:
                this.state.user.role = User.Role.KIOSK;
                break;
            case User.Role.KIOSK:
                this.state.user.role = User.Role.OBSERVER;
                break;
            case User.Role.OBSERVER:
                this.state.user.role = maxRole;  //back to top role
                break;                
            default:
                console.log("May not switch role of type: " + this.role);
        }    
        this.state.user.setDefaultPermissions();
        this.forceUpdate(); //re-render
    }
    
    
    _permissionsCheckboxChanged = (which, isSelected) => {
        if (isSelected)
            this.state.user.setPermission(which);
        else
            this.state.user.clearPermission(which);
        this.forceUpdate();
    }
    
    _setDefaultPermissions = () => {
        this.state.user.setDefaultPermissions();
        this.forceUpdate();
    }
    
    _addDatabase = (database) => {      
        this.state.user.databases.push(database);
        this.forceUpdate(); //re-render        
    }
    
    _removeDatabase = (databaseToRemove) => {
        
        this.state.user.databases = this.state.user.databases.filter((database) => database !== databaseToRemove );  //remove from array
        this.forceUpdate(); //re-render
        
    }
    
    _firstNameFieldChanged = (event) => {
        this.state.user.firstName = event.target.value;
        this.forceUpdate(); //re-render            
    }
    
    _lastNameFieldChanged = (event) => {
        this.state.user.lastName = event.target.value;
        this.forceUpdate(); //re-render            
    }
    
    _emailFieldChanged = (event) => {
        this.state.user.email = event.target.value;
        this.forceUpdate(); //re-render            
    }
    
    _preferredLocationFieldChanged = (event) => {
        this.state.user.preferredLocation = event.target.value;
        this.forceUpdate(); //re-render            
    }
    
    _profileUrlFieldChanged = (event) => {
        this.state.user.profileUrl = event.target.value;
        this.forceUpdate(); //re-render            
    }
    
    _databaseMenuItems = () => {
                
        const availableDatabases = PP.user.databases;  //can add any that the logged in user belongs to

        let menuItems = [];
        
        availableDatabases.forEach((database) => {
            
            if (database !== 'admin') {
                
                const existingDatabases = this.state.user.databases;

                if (!existingDatabases || !existingDatabases.includes(database)) {
                    menuItems.push({label: database, 
                                    selectCallback: () => {this._addDatabase(database);}}
                                   );             
                }  
            }
        });
            
        return menuItems;
        
    };
    
    
    
    
    _fetchCompleteCallback = (response) => {
        this.setBusy(false);
        
        if (this._isSelf) {  //update ourselves 
            
            //Here we have to set ourselves directly.  We can't fetch a user update since the OAuth token will not have changed until we log out
            PP.user = this.state.user;
            if (this._selfDidUpdate) {
                this._selfDidUpdate();
            }
        }
        
        if (this._closePageCallback) {
            this._closePageCallback();  //done, close page
        }
    }
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
    
    _changePasswordCompleteCallback = (response) => {
        this.setBusy(false);
        
        const tempPassword = response.tempPassword;
        
        const messageComponent = (
                <span>
                    <p>{"The temporary password for " + this.state.user.name() + " is: "}</p>
                    <p style={{fontSize: 22, textAlign: 'center', fontFamily: 'monospace'}}>{tempPassword}</p> 
                    <p>{"The user will be required to change their password after the next login."}</p>
                </span>
             );
        
        
        this.showConfirmAlert("Password Changed", "", null, "OK", null, null, null, messageComponent);

    }
    
    _resetPasswordCompleteCallback = (response) => {
        this.setBusy(false);
        this.showConfirmAlert("Password Reset", "User will be sent an email with a link to change their password");
    }
    
    _expirePasswordCompleteCallback = (response) => {
        this.setBusy(false);
        this.showConfirmAlert("Password Expired", "User will need to change password at next login");
    }
    
    
    
        
    _updateUser = () => {

        //Check required information
        if (!this.state.user.firstName) {
            this.showConfirmAlert("Error", "First Name cannot be blank", 'red');
            return;
        }
        if (!this.state.user.lastName) {
            this.showConfirmAlert("Error", "Last Name cannot be blank", 'red');
            return;
        }
        if (this.state.user.firstName.includes("@") || this.state.user.lastName.includes("@")) {
            this.showConfirmAlert("Error", "Name cannot contain an @ character", 'red');
            return;
        }
        if (!this.state.user.email) {
            this.showConfirmAlert("Error", "Email cannot be blank", 'red');
            return;
        }
        if (!Email.validateEmail(this.state.user.email)) {
            this.showConfirmAlert("Error", "Email address is not valid", 'red');
            return;
        }
        if (this.state.user.databases.length === 0 && !this.state.user.hasAllDatabases()) {
            this.showConfirmAlert("Error", "User must be assigned to at least one database", 'red');
            return;
        }
        
        this.setBusy(true);
     
        this.secureJSONFetch("/ppcs/users", 
                             {method: "PUT", body: this.state.user.stringify()}, 
                             this._fetchCompleteCallback,
                             this._fetchErrorCallback); 
    }
    
    
    _changeUserPassword = () => {
        
        this.setBusy(true);
        const id = this.state.user.id;
        this.secureJSONFetch("/ppcs/users/" + id + "/password/change", 
                             {method: "POST"}, 
                             this._changePasswordCompleteCallback,
                             this._fetchErrorCallback);                       
    }
    
    
    _resetUserPassword = () => {
        
        this.setBusy(true);
        const id = this.state.user.id;
        this.secureJSONFetch("/ppcs/users/" + id + "/password/reset", 
                             {method: "POST"}, 
                             this._resetPasswordCompleteCallback,
                             this._fetchErrorCallback);                       
    }
    
    _expireUserPassword = () => {
        
        this.setBusy(true);
        const id = this.state.user.id;
        this.secureJSONFetch("/ppcs/users/" + id + "/password/expire", 
                             {method: "POST"}, 
                             this._expirePasswordCompleteCallback,
                             this._fetchErrorCallback);                       
    }
    

    
    _emailButtonClicked = () => {
        Email.sendEmail(this.state.user.email);
    }
    
    _sendTestEmail = () => {
        this.setBusy(true);
        const id = this.state.user.id;
        this.secureJSONFetch("/ppcs/users/" + id + "/email/test", 
                             {method: "POST"}, 
                             this._testEmailCallback,
                             this._fetchErrorCallback);
    }
    
    _testEmailCallback = (response) => {
        this.setBusy(false);
        this.showConfirmAlert("Success", "Test email sent to " + this.state.user.email);
    }
    
    _confirmDeleteUser = () => {
        
        this.setBusy(true);
        const id = this.state.user.id;     
        this.secureJSONFetch("/ppcs/users/" + id, 
                             {method: "DELETE"}, 
                             this._fetchCompleteCallback,
                             this._fetchErrorCallback);
          
    }
    
    
    _deleteUserPrompt = () => {

        //Check required information
        this.showConfirmAlert("Confirm", 
                              "Really delete user \"" + this.state.user.name() + "\" permanently?",
                              'red',
                              "Cancel", 
                              this._confirmDeleteUser,
                              "Delete",
                              'red');
    }

    
    _manageProfile = () => {
        OpenInNewTab("https://poolpass.okta.com/enduser/settings");
    }
    
    
    render() {
            
        const addDatabaseMenuItems = this._databaseMenuItems();
        const addDatabaseIconColor = addDatabaseMenuItems.length > 0 ? 'gray' : '#EEEEEE';    
        
        const selectedPermissions = this.state.user.getPermissions();
        
        const canAdjustPermissions = this._canChangeRole && this.state.user.hasAdjustablePermissions();
        
        const hasAllDatabases = this.state.user.hasAllDatabases();
        
        return (
            <Fragment>
    
                {this.getConfirmAlertComponent()}
    
                {this._closePageCallback ?
                    <Button color="primary" style={{marginBottom: 20}} onClick={this._closePageCallback}>
                        <ArrowBackIosIcon fontSize='small'/> 
                        <Typography variant="body1">Back to Manage Users</Typography>   
                    </Button>                    
                    : null}
    
                { /*---------------------------- LEFT GRID USER INFO -------------------------------------------*/ null }
    
                <Grid container direction="row" spacing={2}>
                    
                    <Grid item sm={6} xs={12}>
                        <Box>
                        
                              
                            <TextField label="First Name" defaultValue={this.state.user.firstName} onChange={this._firstNameFieldChanged} variant="outlined" fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: this._isReadOnly, maxLength: MAX_NAME_LEN}} InputLabelProps={{ shrink: true}} />
                            <TextField label="Last Name" defaultValue={this.state.user.lastName} onChange={this._lastNameFieldChanged} variant="outlined" fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: this._isReadOnly, maxLength: MAX_NAME_LEN}} InputLabelProps={{ shrink: true}} />
                            
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <TextField label="Email" defaultValue={this.state.user.email} onChange={this._emailFieldChanged} variant="outlined" fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: this._isReadOnly, maxLength: MAX_NAME_LEN}} InputLabelProps={{ shrink: true }} />
                                <Tooltip title={"Send email using the configured mail client"} >
                                     <IconButton edge="start" onClick={this._emailButtonClicked} style={{marginLeft: 4, marginTop: -15}}>
                                         <MailOutlineIcon fontSize="large" style={{color: ThemeColors.mailColor}} />
                                     </IconButton>
                                </Tooltip>             
                            </div>

                            <TextField label="Profile URL" defaultValue={this.state.user.profileUrl} onChange={this._profileUrlFieldChanged} variant="outlined" fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: this._isReadOnly, maxLength: MAX_NAME_LEN}} InputLabelProps={{ shrink: true}} />
                            <TextField label="Preferred Location" defaultValue={this.state.user.preferredLocation} onChange={this._preferredLocationFieldChanged} variant="outlined" fullWidth={true} style={this.styles.textfield} inputProps={{readOnly: this._isReadOnly, maxLength: MAX_NAME_LEN}} InputLabelProps={{ shrink: true}} />

                            <Paper style={{...this.styles.paper, marginTop: 10, marginBottom: 20}}>
                                    <Typography variant="body2" style={this.styles.paperLabel}>Role</Typography>   
                                    <div style={{display: 'flex'}}>
                                    
                                        <Tooltip title={this.state.user.roleDescription()}>
                                            <Typography variant="body2" style={this.styles.role}>{this.state.user.roleString()}</Typography>   
                                        </Tooltip>
                                        
                                        {this._canChangeRole ? 
                                            <Tooltip title="Change Role">
                                                <IconButton edge="end" onClick={this._cycleRole} style={{marginRight: 4, marginTop: -5}}>
                                                    <ImportExportIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null
                                         }
                                    </div>                                    
                            </Paper>
                            
                            <Paper style={{...this.styles.paper, marginTop: 10, marginBottom: 20}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'top'}}>
                                    <Typography variant="body2" style={this.styles.paperLabel}>Permissions</Typography>
                                    <Tooltip title="Select the default permissions based on the current Role">
                                        <Button onClick={this._setDefaultPermissions} disabled={!canAdjustPermissions} style={{marginTop: 5, marginRight: 10, marginBottom: -5}} variant="outlined" fullWidth={false} color='primary'>Set Default</Button>
                                    </Tooltip>
                                </div>
                                <div style={{marginLeft: 15}}>
                                    <PermissionCheckboxes isEmployee={this.state.user.isSiteEmployee()} selected={selectedPermissions} disabled={!canAdjustPermissions} checkboxChanged={this._permissionsCheckboxChanged}/>
                                </div>
                            </Paper>
                          
                            
                        
                            <Paper style={{...this.styles.paper, marginBottom: 0}}>
                                <div style={{display: 'flex'}}>
                                    <Typography variant="body2" style={this.styles.paperLabel}>Databases</Typography>   
                                    
                                    {this._canChangeDatabases && !hasAllDatabases ? 
                                        
                                            <PopupMenu menuIcon={(<AddCircleOutlineIcon style={{color: addDatabaseMenuItems.length === 0 ? ThemeColors.disabledGrey : ThemeColors.addColor}}/>)}  
                                                       menuItems={addDatabaseMenuItems} 
                                                       diasbled={addDatabaseMenuItems.length === 0}
                                                       menuIconStyle={{color: addDatabaseIconColor, marginRight: 4, marginTop: -4}} 
                                                       menuTooltipText={"Add access to a database"}/>
                                            : null
                                     }
                                </div>
                                
                                {hasAllDatabases ? 
                                    <Typography style={{...this.styles.role, paddingBottom: 15}} variant="body2">This Role has access to all databases</Typography> 
                                    :
                                    <List dense>
                                        {this.state.user.databases.map((database, index) =>
                                            (<div key={index}>
                                                {index > 0 ? <Divider style={this.styles.divider}/> : null  /*Dividers above each item except the first*/}
                                                <ListItem>       
                                                    <ListItemText primary={database}/>
                                                    {this._canChangeDatabases ?
                                                        <ListItemSecondaryAction>
                                                            <Tooltip title={"Remove " + (this.state.user.lastName ? this.state.user.name() : "user") + "'s access to " + database}>
                                                                <IconButton edge="end" aria-label="delete" onClick={() => {this._removeDatabase(database)}}>
                                                                  <RemoveCircleOutlineIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>
                                                        : null
                                                    }
                                                </ListItem>
                                             </div>)                       
                                        )}
                                    </List>
                                }
                            </Paper>
                        </Box>
                    </Grid>
               
                {/*---------------------------- RIGHT GRID ACTIONS -------------------------------------------*/ null } 

                    <Grid item sm={6} xs={12}>
                        {this._isSelfProfile ?
                            <Paper style={{marginBottom: 20}}>
                                <Typography variant="body2" style={this.styles.paperLabel}>Authentication</Typography> 
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Button onClick={this._manageProfile} variant="outlined" fullWidth={false} style={{margin: 20}}>Manage Credentials</Button>
                                </div>
                            </Paper>
                            : null
                        }
                    
                        {this._isReadOnly ? null :
                            <Paper style={this.styles.paper}>
                                <Typography variant="body2" style={this.styles.paperLabel}>Actions</Typography> 
                                <Grid container direction='column' alignContent='center' spacing={3} style={{marginTop: 20}}>

                                    <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                        <Button onClick={this._updateUser} variant="outlined" fullWidth={true} color='primary' style={this.styles.button}>{this._isNew ? "Add User" : "Apply Changes"}</Button>
                                    </Grid>

                                    <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                        <Tooltip title="Changes the user's password to a temporary password and displays it. The password must be changed after it is first used to log in.">
                                            <Button onClick={this._changeUserPassword} variant="outlined" fullWidth={true} disabled={this._isNew} style={this.styles.button}>Change Password</Button>
                                        </Tooltip>
                                    </Grid>

                                    <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                        <Tooltip title="Resets the user's password and sends them an email requiring them to set it. If the user has never activated their account, they can do so after receiving the email.">
                                            <Button onClick={this._resetUserPassword} variant="outlined" fullWidth={true} disabled={this._isNew} style={this.styles.button}>Reset Password</Button>
                                        </Tooltip>        
                                    </Grid>

                                    <Grid item style={{marginLeft: 20, marginRight: 20}}>
                                        <Tooltip title="Require the user to change their password when they next log in">
                                            <Button onClick={this._expireUserPassword} variant="outlined" fullWidth={true} disabled={this._isNew} style={this.styles.button}>Expire Password</Button>
                                        </Tooltip>        
                                    </Grid>

                                     {this._canSendTestEmail ?
                                        <Grid item style={{marginLeft: 20, marginRight: 20}}>  
                                            <Tooltip title="Sends a test email to the user from the PoolPass web server.">
                                                <Button onClick={this._sendTestEmail} variant="outlined" fullWidth={true} disabled={this._isNew}  style={this.styles.button}>Send Test Email</Button>
                                            </Tooltip>
                                        </Grid>
                                        : null
                                     }

                                     {this._canDelete ?
                                        <Grid item style={{marginLeft: 20, marginRight: 20}}>                        
                                            <Button onClick={this._deleteUserPrompt} variant="outlined" fullWidth={true} disabled={this._isNew} color='secondary' style={this.styles.button}>Delete User</Button>
                                        </Grid>
                                        : null
                                     }

                                </Grid>

                                <Container style={{height:60, marginTop: 20, marginBottom: 10}}>
                                    {this.getBusyComponent()}
                                </Container>

                            </Paper>
                        }
                    </Grid>
                    
                </Grid>
            </Fragment>
        );

    }
    
}

export default withCookies(EditUserPage);