import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';

import { Button, Box, Grid, Paper, Typography, TextField, Divider, Tooltip} from '@material-ui/core'
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateIcon from '@material-ui/icons/Create';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import StorageIcon from '@material-ui/icons/Storage';

import { User, Permissions } from 'react-frontend-utils' 
import { PP } from '../models/PP'
import { DateUtils } from 'react-frontend-utils'
import { ThemeColors } from '../Theme'
import { RestComponent, Email } from 'react-frontend-utils' 
    

/**
 *  The ManageUser Page provides a list of all users that the user can manage (has a database under their control). This page fetches
 *  a list of all users for the databases the user can manage
 *  
 *  The parent must pass in a prop called onEditUser which is a function to call when adding a user, or a user is selected for editing (goes to the EditUserPage)
 *
 *  This page works different for superadmins because OKTA is too slow. We pull a list of all users but the backend doesn't populate their database names field in
 *  UserInfo. When we edit a user, we must fetch that specific user directly to fully populate the UserInfo.
 *
 *
 */
export class ManageUserPage extends RestComponent {
  
    
    styles = {
        paper: {
            width: '100%',
            height: '100%'
        },
        paperLabel: {
            marginLeft: 15,
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        },
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        switch: {
            marginTop: 2,
            marginRight: 40
        },
        switchLabel: {
            fontSize: 12
        },
        textfield: {
            marginRight: 5,
            marginBottom: 10
        }
     
    }
  
  
    
    
    constructor(props) {
        super(props);
        this.state.userList = [];
        this.state.filteredList = [];
        this.state.specificDatabase = null;
    }


    /**
     * When the page loads, fetch all users for the logged in user's databases
     */
    componentDidMount() {
        super.componentDidMount();
        this._refresh();
    }
    
    _refresh = () => {
        this.setBusy(true);
        
        if (PP.user.hasAllDatabases()) {  //just get all users (but not their databases)
            const specificDatabase = this.state.specificDatabase ? "?databaseNames=" + this.state.specificDatabase : "";
            this.secureJSONFetch("/ppcs/users" + specificDatabase, {}, this._fetchUserCallback, this._fetchUserErrorCallback); 
        }
        else {
            const queryString = PP.user.databases.toString();  //comma separated list of strings 
            this.secureJSONFetch("/ppcs/users?databaseNames=" + queryString, {}, this._fetchUserCallback, this._fetchUserErrorCallback); 
        }
    }
    
    _fetchUserCallback = (response) => {
        if (response) {  
            const userList = response.map((userJson) => new User(userJson));
            this.setState({userList: userList, filteredList: userList});  //re-render
        }
        this.setBusy(false);
    }
    
    _fetchUserErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setState({userList: [], filteredList: []});  //re-render
        this.setBusy(false);
    }


    //sublist of userList containing only the SuperAdmins
    _superadmins = () => {
        return this.state.filteredList.filter((user) => user.role === User.Role.SUPERADMIN);
    }

    //sublist of userList containing only the Admins
    _admins = () => {
        return this.state.filteredList.filter((user) => user.role === User.Role.ADMIN);
    }
    
    _employees = () => {
        return this.state.filteredList.filter((user) => user.role === User.Role.EMPLOYEE);
    }
    
    
    _agents = () => {
        return this.state.filteredList.filter((user) => user.role === User.Role.AGENT);
    }

    //sublist of userList containing only the Managers
    _managers = () => {
        return this.state.filteredList.filter((user) => user.role === User.Role.MANAGER);
    }
    
    //sublist of userList containing only the Attendants
    _attendants = () => {
        return this.state.filteredList.filter((user) => user.role === User.Role.ATTENDANT);
    }
    
    //sublist of userList containing only the Kiosks
    _kioskers = () => {
        return this.state.filteredList.filter((user) => user.role === User.Role.KIOSK);
    }
    
     //sublist of userList containing only the Observers
    _observers = () => {
        return this.state.filteredList.filter((user) => user.role === User.Role.OBSERVER);
    }
    
    
    _emailButtonClicked = () => {

        //extract all email addresses, remove any empty addresses
        const addresses = this.state.filteredList.map((user) => user.email).filter(address => address);
        //concat with commas
        const list = addresses.join(",");
        
        Email.bccSendEmail(list);

    }
    
    
    _download = () => {
        const filename = "User Export on " + DateUtils.downloadTimeString() + ".csv";
        
        
        if (PP.user.isSuperAdmin()) {  //just get all users (but not their databases)
            this.secureFileDownload("/ppcs/users/download", filename, null, this._fetchUserErrorCallback); 
        }
        else {
            const queryString = PP.user.databases.toString();  //comma separated list of strings 
            this.secureFileDownload("/ppcs/users/download?databaseNames=" + queryString, filename, null, this._fetchUserErrorCallback); 
        }
    }
    
    
    /**
     * Called when add button for type is clicked
     * @param {User.Role} role of user to add
     */
    _addUser = (role) => {    
        
        const user = User.createNew(role);
        this.props.onEditUser(user, true);
    }
    
    /**
     * Called when edit button for user is clicked
     * @param {User} user to edit
     */
    _editUser = (user) => {    
        this.props.onEditUser(user, false);
    }

    
    _filterFieldChanged = (event) => {
        const searchString = event.target.value;
        
        if (searchString) { //has a search string   
            const textToSearch = searchString.toLowerCase();
            this.state.filteredList = this.state.userList.filter((user) => 
                user.lastName.toLowerCase().includes(textToSearch) ||
                user.firstName.toLowerCase().includes(textToSearch) ||
                user.email.toLowerCase().includes(textToSearch) ||
                user.databases.toString().includes(textToSearch)           
            );
        } 
        else
            this.state.filteredList = this.state.userList;
        
        this.forceUpdate(); //re-render            
    }
    
    
    
    /**
     * A Paper component with the subcomponents to render a list of the users of the specified type
     * @param {User.Role} type of user
     */
    _userListComponent = (type) => {
        
        const title = User.Role.prettyString(type) + "s"; //plural
        
        let usersToView;
        switch (type) {
            
            case User.Role.SUPERADMIN:
                usersToView = this._superadmins();
                break;
            case User.Role.AGENT:
                usersToView = this._agents();
                break;
            case User.Role.EMPLOYEE:
                usersToView = this._employees();
                break;
            case User.Role.ADMIN:
                usersToView = this._admins();
                break;
            case User.Role.MANAGER:
                usersToView = this._managers();
                break;
            case User.Role.ATTENDANT:
                usersToView = this._attendants();
                break;
            case User.Role.KIOSK:
                usersToView = this._kioskers();
                break;
            case User.Role.OBSERVER:
                usersToView = this._observers();
                break
            default:
                usersToView = [];
                break;
        }
        
        //Only administrators or above can create new users, and only when the type is not the same as they are
        const canCreateNewUser = (PP.user.isAdministrator() || PP.user.hasPermissionTo(Permissions.CREATE_USERS)) && type !== PP.user.role;
                
        return (
            
            <Paper style={{...this.styles.paper, marginBottom: 0}}>
    
                <div style={{display: 'flex', height: 40}}>

                     <Typography variant="body2" style={this.styles.paperLabel}>{title + " (" + usersToView.length + ")"}</Typography>  
                     
                     {canCreateNewUser ? 
                        <Tooltip title={"Add a new user to " + title + " role"}>
                           <IconButton edge="end" onClick={() => {this._addUser(type)}} style={{marginTop: -4, marginRight: 4}}>
                               <AddCircleOutlineIcon style={{color: ThemeColors.addColor}}/>
                           </IconButton>
                       </Tooltip>
                       : null
                     }
                </div>
                
                {this.getBusyComponent()}

                <List dense>
                    {usersToView.map((user, index) =>
                        (<div key={index}>
                            {index > 0 ? <Divider style={this.styles.divider}/> : <Divider/>  /*Smaller dividers except the first*/}
                            <ListItem>                                
                                <ListItemText primary={user.nameReversed()} 
                                              secondary={"Last Login: " + (user.lastLogin ? PP.checkInTimeFormat(user.lastLogin) : "Never")}/>
                                    
                                    { user.isNew() ? 
                                        <Typography variant="overline" style={{color: ThemeColors.newUser, marginRight: 40}}>NEW</Typography> : null
                                    }
                                    
                                    { user.hasAlertStatus() ? 
                                        <Tooltip title={"Status: " + user.status}>
                                            <ListItemIcon>
                                                <ReportProblemOutlinedIcon fontSize='small' style={{color: ThemeColors.userWarnStatus, marginRight: 10}}/>
                                             </ListItemIcon>
                                        </Tooltip> : null }
                                             
                                     
                                    { user.databases.length > 0 ? 
                                        <Tooltip title={"Databases: " + user.databases.toString().replaceAll(",", ", ")}>
                                            <ListItemIcon>
                                                <StorageIcon fontSize='small'/><div style={{marginRight: 40, marginTop: 8, fontSize: 12, fontWeight: 'bold'}}>{user.databases.length}</div>
                                            </ListItemIcon>
                                        </Tooltip> 
                                        : null
                                    }
                                     
                                    <ListItemSecondaryAction>
                                     
                                        <Tooltip title={"Edit " + user.name()}>
                                            <IconButton edge="end" aria-label="delete" onClick={() => {this._editUser(user)}}>
                                              <CreateIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>                                                   
                            </ListItem>
                         </div>)                       
                    )}
                </List>
            </Paper>
        );
    }
    
    
    
    render() {
        
        const filterColor = this.state.filteredList.length > 0 ? 'primary' : 'secondary';
        
        
        //Based on the permission of the logged in user, render the different panes for each User.Role
        let renderGrids = [];
        switch (PP.user.role) {           
            case User.Role.SUPERADMIN:
                renderGrids = [this._userListComponent(User.Role.SUPERADMIN), 
                              this._userListComponent(User.Role.AGENT), 
                              this._userListComponent(User.Role.EMPLOYEE), 
                              this._userListComponent(User.Role.ADMIN), 
                              this._userListComponent(User.Role.MANAGER), 
                              this._userListComponent(User.Role.ATTENDANT),
                              this._userListComponent(User.Role.KIOSK),
                              this._userListComponent(User.Role.OBSERVER)];
                break;
            default:
                renderGrids = [this._userListComponent(User.Role.ADMIN), 
                               this._userListComponent(User.Role.MANAGER), 
                               this._userListComponent(User.Role.ATTENDANT),
                               this._userListComponent(User.Role.KIOSK),
                               this._userListComponent(User.Role.OBSERVER)];
                break;    
        }
        

        return (
            <Fragment>
        
                {this.getConfirmAlertComponent()}        

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}}>
     
                    <Tooltip title={"Export and Download all users to a .csv file"} >
                        <IconButton edge="start" onClick={this._download} style={{marginTop: 4, marginRight: 10}}>
                            <CloudDownloadIcon/>
                        </IconButton>
                    </Tooltip>  
                    
                    <Tooltip title={"Send Bcc email to filtered users using the configured mail client"} >
                        <IconButton edge="start" onClick={this._emailButtonClicked} style={{marginTop: 4, marginRight: 10}}>
                            <MailOutlineIcon style={{color: ThemeColors.mailColor}} />
                        </IconButton>
                    </Tooltip>    
            
                    <Tooltip title="Filter on a user's first or last name, email, or a database name">
                        <TextField label="Filter" onChange={this._filterFieldChanged} color={filterColor} margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />
                    </Tooltip>
                    
                    {PP.user.hasAllDatabases() ?
                        <Box style={{display: 'flex', alignItems: 'center', border: '1px solid gray', borderRadius: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 10}}>
                            <Tooltip title="Search users for a specific database only">
                                <TextField label="Database" onChange={(event) => {this.setState({specificDatabase: event.target.value})}} 
                                           margin='dense' style={this.styles.textfield} InputLabelProps={{ shrink: true }} />
                            </Tooltip>
                            <Button variant='outlined' onClick={this._refresh}>Search</Button>
                        </Box>
                        : null
                    }

                </div>
        
                <Box style={{marginTop: 20}}>
                    <Grid container direction="row" spacing={2} justify="center">

                        {renderGrids.map((grid, index) => (<Grid key={index} item md={6} xs={12}>{grid}</Grid>) )}

                    </Grid>
                </Box>
            </Fragment>
        );

    }
    
}


export default withCookies(ManageUserPage);