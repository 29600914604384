import React from 'react';



function EmailTermsOfService(props) {

    const styles = {
        container: { fontFamily: 'Arial, sans-serif', lineHeight: '1.6', margin: '40px' },
        heading1: { fontSize: '24px', color: '#333' },
        heading2: { fontSize: '20px', marginTop: '20px', color: '#333' },
        list: { margin: '10px 0', paddingLeft: '20px' },
        hr: { margin: '20px 0', border: 'none', borderTop: '1px solid #ccc' }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading1}>Terms of Service: Acceptable Use Policy for Access Granted Systems' Email Service</h1>
            
            <h2 style={styles.heading2}>1. Acceptance of Policy</h2>
            <p>By using Access Granted Systems' email messaging service ("Service"), you agree to comply with this Acceptable Use Policy. Failure to adhere to this policy may result in suspension or termination of your account.</p>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>2. Permitted Use</h2>
            <p>Our Service is intended for our clients to communicate with their patrons, community members, or those with a relationship to the community regarding official community business. Examples of permissible email content include:</p>
            <ul style={styles.list}>
                <li>Membership renewal reminders</li>
                <li>Event invitations and updates</li>
                <li>Community announcements</li>
                <li>Important policy or service changes</li>
                <li>Member-exclusive offers or benefits</li>
            </ul>
            <p>Clients must ensure that access to the Service is granted only to their employees who are authorized to send official community communications. The Service may not be used for personal, non-community-related, or unauthorized communications.</p>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>3. Prohibited Content</h2>
            <p>You may not use our Service to send emails that contain, promote, or facilitate:</p>
            <ul style={styles.list}>
                <li>Spam, phishing, or fraudulent schemes</li>
                <li>Unsolicited bulk email (UBE) or Unsolicited Commercial Email (UCE) outside the scope of member communications</li>
                <li>Content that is illegal, abusive, defamatory, obscene, or otherwise harmful</li>
                <li>Malware, viruses, or any malicious software</li>
                <li>Content that infringes on intellectual property rights, including copyright, trademarks, or patents</li>
                <li>Misinformation or deceptive content, including misleading advertising or impersonation</li>
                <li>Hate speech, harassment, threats, or incitement of violence</li>
            </ul>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>4. Compliance with Laws and Regulations</h2>
            <p>Users must comply with all applicable laws, including but not limited to:</p>
            <ul style={styles.list}>
                <li>The CAN-SPAM Act (for U.S. users)</li>
                <li>The General Data Protection Regulation (GDPR) (for EU users)</li>
                <li>Any other applicable local, state, national, or international laws related to email communication</li>
            </ul>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>5. Consent Requirements</h2>
            <ul style={styles.list}>
                <li>You will only send emails to individuals who have an active membership, subscription, or established relationship with your organization</li>
                <li>You will stop sending emails to individuals who no longer want to receive your communications</li>
                <li>You must supply a valid reply-to email address so that receipients can contact you in order to opt-out of communications</li>
            </ul>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>6. Account and Security Responsibility</h2>
            <ul style={styles.list}>
                <li>You are responsible for maintaining the security of your account and preventing unauthorized access.</li>
                <li>You may not share login credentials or allow unauthorized third parties to use your account.</li>
                <li>If you suspect unauthorized use of your account, you must notify us immediately.</li>
            </ul>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>7. Monitoring and Enforcement</h2>
            <p>We reserve the right to:</p>
            <ul style={styles.list}>
                <li>Monitor email usage to ensure compliance with this policy.</li>
                <li>Investigate any reported violations or suspicious activity.</li>
                <li>Take appropriate action, including suspension or termination of the Service or your account, if violations occur.</li>
            </ul>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>8. Modifications to This Policy</h2>
            <p>We may update this policy at any time. Continued use of the Service after changes are posted constitutes acceptance of the updated terms.</p>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>9. Liability and Compliance</h2>
            <ul style={styles.list}>
                <li>We are not liable for any damages, losses, or legal actions resulting from your use of the Service</li>
                <li>You agree to indemnify and hold us harmless from any claims related to your email communications</li>
                <li>We reserve the right to cooperate with law enforcement and regulatory authorities in investigations of suspected illegal activities</li>
            </ul>
            <hr style={styles.hr} />
            
            <h2 style={styles.heading2}>10. Privacy</h2>
            <p>We are committed to protecting the privacy of your communications and will not access, monitor, or disclose your private messages except as 
            required to provide our services or as permitted by law. However, we may disclose your communications if we are compelled to do so by a valid legal 
            process, such as a subpoena or court order, or if necessary to enforce our terms, prevent fraud, or protect the rights,
             property, or safety of our users or others.
            </p>
            <hr style={styles.hr} />
        
        </div>
    );
};

export default EmailTermsOfService;

