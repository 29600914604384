import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';

import { AppBar, Toolbar, Typography, Button, IconButton, Container } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import StarIcon from '@material-ui/icons/Star';

import PoolPassTheme, { ThemeColors } from '../Theme'
import { RestComponent, HomepageContext, StyledTooltip, TextEntryPopover, Permissions } from 'react-frontend-utils'
import NotifyReveal from '../components/NotifyReveal'
import { OpenInNewTab, NOTIFY_INDEX } from '../App'
import { PP } from '../models/PP'
import { User } from 'react-frontend-utils'
import { MainMenu } from '../components/MainMenu'
import { WelcomePage } from '../pages/WelcomePage'
import { LoggedInPage } from '../pages/LoggedInPage'
import PreferencesPage from '../pages/PreferencesPage'
import EditUserPage  from '../pages/EditUserPage'
import ManageUserPage from '../pages/ManageUserPage'
import ManageAppsPage from '../pages/ManageAppsPage'
import FinancialsPage from '../pages/FinancialsPage'
import PoolPassPage from '../pages/PoolPassPage'
import ManageMarketplacePage from '../pages/ManageMarketplacePage'
import BillingPage from '../pages/BillingPage'
import ExpiredPage from '../pages/ExpiredPage'
import { HelpPage } from '../pages/HelpPage'
import { SwitchDatabasePage } from '../pages/SwitchDatabasePage'
import { logo } from '../utils/Image'


//This is the main page for the Web Portal. It contains the AppBar and MainMenu and handles login/logout.
//Below the AppBar, the HomePage selects one Page out of the available Pages to display, based on what
//is selected by the user. The WecomePage is the initial page shown before login.


//These are the available Pages that can be selected from the MainMenu that will be displayed below the AppBar
export const Pages = {
    WELCOME: "WELCOME",
    EXPIRED: "EXPIRED",
    POOLPASS: "POOLPASS",
    SWITCH_DATABASE: "SWITCH_DATABASE",
    PREFERENCES: "PREFERENCES",
    MANAGE_USERS: "MANAGE_USERS",
    FINANCIALS: "FINANCIALS",
    MANAGE_APPS: "MANAGE_APPS",
    MANAGE_MARKETPLACE: "MANAGE_MARKETPLACE",
    BILLING: "BILLING",
    EDIT_USER: "EDIT_USER",
    PROFILE: "PROFILE",
    HELP: "HELP",
    
    //Returns true if the type is one of the MainMenuSwitch types, false otherwise
    isOneOf: (type) => {
        switch (type) {
            case Pages.WELCOME:
            case Pages.EXPIRED:
            case Pages.POOLPASS:
            case Pages.SWITCH_DATABASE:
            case Pages.PREFERENCES:
            case Pages.MANAGE_USERS:
            case Pages.FINANCIALS:
            case Pages.MANAGE_APPS:
            case Pages.MANAGE_MARKETPLACE:
            case Pages.BILLING:
            case Pages.EDIT_USER:
            case Pages.PROFILE:
            case Pages.HELP:
                return true;
            default:
                return false;
        }
    }
};
Object.freeze(Pages);



const AppBarIconButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(IconButton);

const AppBarButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(Button);



class Home extends RestComponent {
  
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    }
  
 
    constructor(props) {
        super(props);
        this.state.isAuthenticated = false;       //true if the current user has been authenticated via OAuth
        this.state.selectedPage = Pages.WELCOME;  //the current page, one of the Pages item, initially the welcome entry page
        this.state.userToEdit = null;             //the user to edit on the EDIT_USER page
        this.state.serverError = null;
        this.state.isMobile = false;
        this.state.helpPage = null;               //selected help page
        this.state.helpHeight = window.innerHeight;
        this.state.hasPortal = false;             //if we come back with valid currency, then there is a marketplace portal
        this.state.notifyReveal = false;
    }


    /**
     * When the Home page loads, see if the user is currently authenticated
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        this._fetchCurrentUser();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 600, helpHeight: window.innerHeight - 200 });  //custom, split between bootstrap and mui
    }


    
    _fetchCurrentUser = () => {
        this.incrementBusy();
        this.secureJSONFetch("/auth/currentUser", {}, this._checkUserCallback); 
    }


    _fetchFeatures = () => {
                
        //If database is suspended, this will error
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/available", {}, null, this._databaseNotAvailable); 
        
        //By default check-in is enabled, but if we fail this check we will disable check-in
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/checkInCheck", {}, null, this._checkInCheckFailed); 

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/currency", {}, this._fetchCurrencyCallback, this._fetchCurrencyFailed); 

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/printingCheck", {}, this._checkPrintingCallback, this._printingCheckFailed); 

        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/attendantPurchaseCheck", {}, this._attendantPurchaseCheckCallback, this._attendantPurchaseCheckFailed); 

    }
    

    /**
     * Callback to be executed from fetching the current user
     * @param {Object} response null if there is no current authenticated user, or a JSON object describing the user
     */
    _checkUserCallback = (response) => {
                
        this.decrementBusy();

        if (response === null) {
            this.setState(({isAuthenticated: false}));  //user is not authenticated
            console.log("No user is currently logged in");
            PP.user = null;  //nullify any current user
            PP.selectedDatabase = null;
            PP.currency = null;
            
        } 
        else {
            PP.user = new User(response);  //create the global user
            
            const lastDB = PP.getLastDatabase();     
            if (lastDB && PP.user.databases.includes(lastDB))  //a last database was set and this user has it in their list
                PP.selectedDatabase = lastDB;
            else {  //not previously set, or user does not have rights to it
                PP.selectedDatabase = PP.user.defaultDatabase();
                PP.setLastDatabase(PP.selectedDatabase);  //store it for next time
            }
 
            if (PP.user.preferredLocation)
                PP.setLocation(PP.user.preferredLocation);
    
            this.setState({isAuthenticated: true});  //re-render
            console.log("User: " + PP.user.name()  + " (" + PP.user.id + "), a \"" + PP.user.role + "\", logged in, available databases are: " + PP.user.databases.toString());
            console.log(PP.user.name() + " has these permissions: " + JSON.stringify(PP.user.getPermissions()));
                
            //Go fetch the currency and printing settings for the selected database
            this._fetchFeatures();
            
            //If user has new notifications, show the notify reveal after a slight delay
            if (PP.user.notifyIndex < NOTIFY_INDEX)
                setTimeout(() => {this.setState({notifyReveal: true});}, 800);
            
        }
    }

    //Response is an ISO currency code
    _fetchCurrencyCallback = (response) => {
        if (response) {
            PP.currency = response;
            this.setState({hasPortal: true});
        }
    }
    
    _fetchCurrencyFailed = (error) => {
        PP.currency = "???";
        this.setState({hasPortal: false});
    }
    
    //Response is an object { enabled: [array of kinds]} where array of kinds are (EPASS, CARD, PDF)
    _checkPrintingCallback = (response) => {
        if (response) {
            console.log("Printing check: ", response.enabled);
            PP.printCapabilities = response.enabled;
        }
    }
    
    _printingCheckFailed = (error) => {
        console.log("Printing disabled: ", error);
        PP.printCapabilities = [];
    }
    
    
    _attendantPurchaseCheckCallback = (response) => {
        console.log("Attendant Marketplace Purchase: enabled");
        PP.attendedMarketplacePurchaseEnabled = true;
        
    }
    
    _attendantPurchaseCheckFailed = (error) => {
        console.log("Attendant Marketplace Purchase: disabled");
        PP.attendedMarketplacePurchaseEnabled = false;        
    }
    
    
    _checkInCheckFailed = (error) => {
        console.log("Check in disabled: ", error);
        PP.checkInDisabled = true;
    }
    
    _databaseNotAvailable = (error) => {

        // Get the current date
        const currentDate = new Date();

        // Extract the current year
        const currentYear = currentDate.getFullYear();

        const startDate = new Date(currentYear, 2, 1);  // march 1 this year
        const endDate = new Date(currentYear, 9, 31);   // sept 31 this year

        // Check if the current date falls within the seasonal range
        const isInSeason = currentDate >= startDate && currentDate < endDate;

        if (!isInSeason)
            this.showConfirmAlert("Database \"" + PP.selectedDatabase + "\" Unavailable", "This is currently the off-season for seasonal subscriptions. To gain access, please contact your account representative.", 'red');
        else
            this.showConfirmAlert("Database \"" + PP.selectedDatabase + "\" Suspended", "To gain access, please contact your account representative.", 'red');

    }
    
    
    //Update users' notify index to the current one (user acknowledged the notification)
    _updateNotifyIndex = () => {
        if (PP.user) {
            
            //update local user's index, hide the notify reveal, and tell server
            PP.user.notifyIndex = NOTIFY_INDEX;  
            this.setState({notifyReveal: false});
            this.secureJSONFetch("/ppcs/currentUser/notifyIndex/" + NOTIFY_INDEX, {method: "POST"}); 
        }
    }

     /**
     * User action by pressing the LOGOUT button
     */
     logout() {
        super.logout( () => {
            this.setState(({isAuthenticated: false}));  //user is not authenticated
            PP.user = null;
            PP.selectedDatabase = null;
            PP.currency = null;
        });
    }
    
    _handleRefreshClick = () => {
        this.forceUpdate();
    }

    
    sessionExpired = () => {
        PP.user = null;
        PP.selectedDatabase = null;
        this.setState({selectedPage: Pages.EXPIRED, isAuthenticated: false}); 
    }


    /**
     * Callback from the MainMenu component when a user selects an item from the menu 
     * @param {Pages item} selectedItem one of the Pages items
     */
    mainMenuCallback = (selectedItem) => {
        
        if (Pages.isOneOf(selectedItem)) {
            this.setState({selectedPage: selectedItem}); 
            console.log("Switching page to " + selectedItem);
        }
        else {
            console.log("Invalid Page passed to mainMenuCallback");
        }
             
    }
    
    
    /**
     * Callback when the operator selects a new database
     * @param {type} selectedDatabase name of the desired database
     */
    selectDatabaseCallback = (selectedDatabase) => {
        console.log("Switching database to " + selectedDatabase);  
        PP.selectedDatabase = selectedDatabase;
        PP.setLastDatabase(selectedDatabase);
         
        //Go fetch the currency and printing settings for the new database
        this._fetchFeatures();
            
        this.forceUpdate(); //force an update here - selectedDatabase changes in PP, but this won't trigger a re-render since it is not part of our state
    }
    
    /**
     * Callback when the operator selects to edit a user from the Manage page, switches to Edit Page
     * @param {User} userToEdit the user, which may be partially empty when adding a new one. For those users that have all databases (superadmin and site agent), 
     *                we must refetch the user since their databases were not fully populated by the backend.
     * @param {boolean} isNew true when this is a new user to create, false to edit existing user
     */
    userEditCallback = (userToEdit, isNew) => {
        
        if (PP.user.hasAllDatabases() && !isNew)
            this.secureJSONFetch("/ppcs/user/" + userToEdit.id, {}, this._fetchUserCallback, this._fetchUserErrorCallback); 
        else
            this.setState({selectedPage: Pages.EDIT_USER, userToEdit: userToEdit});  //re-render
    }
    
    _fetchUserCallback = (response) => {
        if (response) {
            const userToEdit = new User(response);
            this.setState({selectedPage: Pages.EDIT_USER, userToEdit: userToEdit});  //re-render
        }
        this.setBusy(false);
    }
    
    _fetchUserErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }
    

    /**
     * Callback when the operator goes back from the Edit page, return to the Manage page
     */
    closeUserEditCallback = () => {  //Go back to MANAGE USER page
        this.setState({selectedPage: Pages.MANAGE_USERS, userToEdit: null});  //re-render
    }       

    /**
     * Callback when user profile is updated, force update to modify name in app bar
     */
    profileUpdatedCallback = () => {
        this.forceUpdate();
    }

    _showHealth = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
            port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/monitoring"; 
    }
    
    _showLogs = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
            port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/actuator/logfile"; 
    }
    
    _gotoPortal = () => {
        OpenInNewTab("//" + window.location.hostname + ":" + window.location.port + "/patron?facility=" + PP.selectedDatabase);         
    }

    _composeEmail = () => {
        OpenInNewTab("//" + window.location.hostname + ":" + window.location.port + "/email"); 
    }


    _specialAction = () => {
        this.setState({specialActionPromptOpen: true});
    }

    _specialActionPromptOkCallback = (text) => {

        if (text) {
            this.incrementBusy();
            this.secureJSONFetch("/ppcs/special/" + encodeURIComponent(text),
                                {method: "POST"}, 
                                this._specialActionResponseCallback, this._fetchErrorCallback); 


        }
        this.setState({specialActionPromptOpen: false}); //close prompt
    }
    
    _specialActionResponseCallback = () => {
        this.showConfirmAlert("Success", "Completed. Check server logs for details", 'green');
        this.setBusy(false);
    }
    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.setBusy(false);
    }

    render() {

        //Set the login button based on the state of the authentication
        const loginLogoutButton = this.state.isAuthenticated ?
            <AppBarButton color="inherit" onClick={() => { this.logout(); }}>LOGOUT</AppBarButton>
            :
            <AppBarButton color="inherit" onClick={() => { this.login(); }}>LOGIN</AppBarButton>;


        //Only shows the main menu when authenticated and user is not kiosk
        const showMainMenu = this.state.isAuthenticated && !PP.user.isKiosk();

        let limitMaxWidth = true;
        
        //Selects the current page to view
        const viewingPage = (() => {
                       
            switch (this.state.selectedPage) {

                case Pages.WELCOME:   
                    if (!this.state.isAuthenticated) //only WelcomePage when not authenticated
                        return <div>
                                    <WelcomePage loginFunc={(provider) => this.login(provider)}/>;
                                    <div style={{marginTop: 30}}/>
                                    {this.getBusyComponent()}
                                </div>;
                    else if (this.props.membershipID !== undefined) {  //if null, we also want to visit the page without a specific id
                        if (this.props.database)
                            PP.selectedDatabase = this.props.database;
                        return <PoolPassPage membershipID={this.props.membershipID}/>;
                    }
                    else
                        return <LoggedInPage goToPoolPass={() => {this.setState({selectedPage: Pages.POOLPASS});}}/>;
                    
                case Pages.EXPIRED:   
                    return <ExpiredPage/>;
                    
                case Pages.POOLPASS:
                    limitMaxWidth = false;
                    return <PoolPassPage/>;
                
                case Pages.MANAGE_USERS:
                    return <ManageUserPage onEditUser={this.userEditCallback}/>;
                    
                case Pages.FINANCIALS:
                    return <FinancialsPage/>;
                    
                case Pages.MANAGE_APPS:
                    return <ManageAppsPage availableDatabases={PP.user.databases}/>;
                    
                case Pages.MANAGE_MARKETPLACE:
                    limitMaxWidth = false;
                    return <ManageMarketplacePage/>;
                    
                case Pages.BILLING:
                    limitMaxWidth = false;
                    return <BillingPage/>;    
                    
                case Pages.PREFERENCES:
                    return <PreferencesPage locationDidUpdate={this.profileUpdatedCallback}/>;  //location may have changed, need to update app bar
                
                case Pages.EDIT_USER:
                    return <EditUserPage key="edit" userToEdit={this.state.userToEdit} closePage={this.closeUserEditCallback} selfDidUpdate={this.profileUpdatedCallback}/>; //note: key needed because of duplicate EditUserPage
                
                case Pages.PROFILE:
                    return <EditUserPage key="profile" userToEdit={PP.user} isSelfProfile={true} selfDidUpdate={this.profileUpdatedCallback}/>;  //note: key needed because of duplicate EditUserPage
               
                case Pages.HELP:
                    return <HelpPage helpHeight={this.state.helpHeight} visiblePage={this.state.helpPage} selectPageCallback={(page) => {this.setState({helpPage: page});}} />;
                    
                case Pages.SWITCH_DATABASE:
                    return <SwitchDatabasePage selectDatabaseCallback={this.selectDatabaseCallback} availableDatabases={PP.user.databases} selectedDatabase={PP.selectedDatabase} />;
                    
                default:
                    return <div>Page Not Found</div>;

            }
        })();
        
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
        
        let dbLocString = null;
        if (PP.selectedDatabase) {
            dbLocString = "Database: " + PP.selectedDatabase;
            
            if (!PP.user.isObserver())  //observers do not have a location
                dbLocString += " @ " + PP.getLocation();       
        }
        
        const gutterMargin = this.state.isMobile ? 8 : 20;
        
               
        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={PoolPassTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  
                        
                             
                        <TextEntryPopover isOpen={this.state.specialActionPromptOpen} showSkip={false} multiline={false} title="Special Server Action" 
                                 okCallback={this._specialActionPromptOkCallback} cancelCallback={() => this.setState({specialActionPromptOpen: false})}/>
    

                        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                                <Toolbar disableGutters={true}>
                                     {logo}
                                     <Typography variant="h5" style={this.styles.appTitle}>Membership Portal</Typography>

                                     {PP.user && PP.user.hasPermissionTo(Permissions.DEVELOP) ? 
                                        <div>
                                            <AppBarIconButton edge="end" onClick={this._showLogs} style={{marginRight: 5}} >
                                                <DescriptionOutlinedIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                            <AppBarIconButton edge="end" onClick={this._showHealth} style={{marginRight: 5}} >
                                                <FavoriteBorderIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                            <AppBarIconButton edge="end" onClick={this._specialAction} style={{marginRight: 5}} >
                                                <StarIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                        </div>
                                        : null
                                     }

                                    {PP.user && PP.user.hasPermissionTo(Permissions.SEND_EMAIL) ?
                                        <StyledTooltip title="Compose New Email">
                                            <AppBarIconButton edge="end" onClick={this._composeEmail} style={{marginRight: 10}} >
                                                <MailOutlineIcon fontSize="small" style={{color: 'white'}} />
                                            </AppBarIconButton>
                                        </StyledTooltip>
                                        : null
                                     }
                                     
                                     {this.state.hasPortal ? 
                                        <StyledTooltip title={"Go to Patron Portal for " + PP.selectedDatabase}>
                                            <AppBarIconButton edge="end" onClick={this._gotoPortal} style={{marginRight: 10}} >
                                                <AccountBoxOutlinedIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                        </StyledTooltip>
                                        : null
                                     }
                                      
                                     {loginLogoutButton}
                                     <div style={{padding: 5}}/>
                                     {showMainMenu ? <MainMenu selectPageCallback={this.mainMenuCallback} currentPage={this.state.selectedPage}/> : null}


                                </Toolbar>
                                <div style={{display: "flex", flexWrap: "nowrap"}}>
                                    <Typography variant="subtitle1" style={{flexGrow: 1, fontSize: this.state.isMobile ? 13 : 16}}>{dbLocString}</Typography>
                                    <Typography variant="subtitle1" style={{textAlign: 'right', fontSize: this.state.isMobile ? 13 : 16}}>{PP.user ? ("User: " + PP.user.name()) : null}</Typography>
                                </div>
                            </div>
                        </AppBar>


                        {serverErrorMessage}

                        { /*No longer used    <NotifyReveal reveal={this.state.notifyReveal} acknowledge={this._updateNotifyIndex}/> */}

                        <Container maxWidth={limitMaxWidth ? 'lg' : false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                            {viewingPage} 
                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(Home);