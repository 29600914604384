import { withCookies } from 'react-cookie';

import { AbstractStatisticsTab } from './AbstractStatisticsTab';
import { Occupancy } from '../../models/Occupancy'

import { ThemeColors } from '../../Theme'

import { PP } from '../../models/PP'
import { DateUtils } from 'react-frontend-utils'

export class OccupancyStatsTab extends AbstractStatisticsTab {
  
    
  
    _options = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.occupancyPurple],  //red and blue
        stroke: {
            curve: 'stepline',
            width: 1
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: this._datetimeFormatter(true)
            }
        },
        tooltip: {
            x: {
                format: this._dateFormat(true)
            },
            y: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        }
    }

    constructor(props) {
        super(props, false); 
        this._chartOptions = this._options;
        this._chartType = "area";
        this._chartFetchPath = "/ppcs/databases/" + PP.selectedDatabase + "/stats/occupancy";
        this._chartDataHandlerCallback = this._processChartData;
        this._hasLocation = true;
        this._hasAggregate = false;
        this._hasTime = true;
        this._hasTimeRange = true;
        this._minZoomTime = 2;  //hours
        this._hasDownload = true;
    }
    
    _processChartData = (response) => {
        
        const occData = response.map(stat => new Occupancy(stat));
        
        if (occData.length === 0) {  //no data
            this.setState({chartData: null});
            return;
        }

        if (occData.length >= 10000) {   //max data
            this.showConfirmAlert("Warning", "Only the 10000 newest records returned. To see all records, use the download tool.", 'red');
        }

        
        //Extend the last stat value to the current time
        const lastStat = occData[occData.length-1];
        occData.push(Occupancy.createNowStat(lastStat.occupancy));
        
        const seriesData = occData.map(stat => [stat.time, stat.occupancy]);
        
        const series = [
            {
              name: "Occupancy",
              data: seriesData
            }          
        ];
      
        this.setState({chartData: series});
    }
    
    _download = () => {
                
        const locationParam = this._locationParam(this.state.selectedLocation);        
        const filename = "Occupancy Statistics at " + this.state.selectedLocation + " from " + PP.selectedDatabase + " on " + DateUtils.downloadTimeString() + ".csv";
        
        this.secureFileDownload("/ppcs/databases/" + PP.selectedDatabase + "/stats/occupancy/download", filename, null, null, locationParam); 
    
    }
    
}
  
export default withCookies(OccupancyStatsTab);