import React from 'react';
import { withCookies } from 'react-cookie';

import { Tooltip, Button, FormControl, FormControlLabel, Checkbox, TextField, Grid } from '@material-ui/core'

import { PP } from '../models/PP'
import { RestComponent, TextEntryPopover, Currency } from 'react-frontend-utils' 

import LineWeightIcon from '@material-ui/icons/LineWeight';

/**
 * The Settings tab has controls for working with the Marketplace
 */

const MIN_DEPOSIT_AMOUNT = 5.0;


export class SettingsTab extends RestComponent {
  

    constructor(props) {
        super(props);
                
        this.state.confirmReconcilePopoverOpen = false;
        this.state.purchaseOn = false;
        this.state.canDeposit = false;
        this.state.publicList = false;
        this.state.attendantAssistedPurchase = false;
        this.state.marketEmail = "";
        this.state.ticketEnabled = false;
        this.state.marketURL = "";
        this.state.minDeposit = MIN_DEPOSIT_AMOUNT;
        this.state.minDepositValue = MIN_DEPOSIT_AMOUNT;
        this.state.termsAndConditions = "";
        this.state.enableServiceFee = false;
        this.state.enableMatchingAGSServiceFee = false;
        this.state.serviceFeePercent = 0;
        this.state.serviceFeeFixedAmount = 0;
    }
    
    componentDidMount() {
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/settings",
                             {}, 
                             this._fetchSettingsCallback, this._fetchErrorCallback); 
    }
    

    
    //Get response, which is a JsonMarketSettings object
    _fetchSettingsCallback = (response) => {
        this.decrementBusy();
        if (response) {
            this.setState({purchaseOn: response.purchaseOn,
                           canDeposit: response.canDeposit,
                           publicList: response.publicList,
                           attendantAssistedPurchase: response.attendantAssistedPurchase,
                           marketEmail: response.marketEmail,
                           ticketEnabled: response.ticketEnabled,
                           marketURL: response.marketURL,
                           minDeposit: response.minDeposit,
                           minDepositValue: response.minDeposit,
                           termsAndConditions: response.termsAndConditions,
                           enableServiceFee: response.enableServiceFee,
                           enableMatchingAGSServiceFee: response.enableMatchingAGSServiceFee,
                           serviceFeePercent: response.serviceFeePercent,
                           serviceFeeFixedAmount: response.serviceFeeFixedAmount
                          });
        }
    }
    
    _applySettings = () => {
        
        const newSettings = {purchaseOn: this.state.purchaseOn,
                             canDeposit: this.state.canDeposit,
                             publicList: this.state.publicList,
                             attendantAssistedPurchase: this.state.attendantAssistedPurchase,
                             marketEmail: this.state.marketEmail,
                             ticketEnabled: this.state.ticketEnabled,
                             marketURL: this.state.marketURL,
                             minDeposit: this.state.minDepositValue,
                             termsAndConditions: this.state.termsAndConditions,
                             enableServiceFee: this.state.enableServiceFee,
                             enableMatchingAGSServiceFee: this.state.enableMatchingAGSServiceFee,
                             serviceFeePercent: this.state.serviceFeePercent,
                             serviceFeeFixedAmount: this.state.serviceFeeFixedAmount
                            };
         
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/settings",
                             {method: "POST", body: JSON.stringify(newSettings)}, 
                             () => this._updatSettingsSuccessful(this.state.attendantAssistedPurchase), this._fetchErrorCallback); 
         
        
    }
    
    _updatSettingsSuccessful = (attendantAssistedPurchase) => {
        this.showConfirmAlert("Success", "New settings applied", 'green');
        this.decrementBusy();
        
        PP.attendedMarketplacePurchaseEnabled = attendantAssistedPurchase;  // we need to update this field here, since it won't be refreshed automatically
    }
    
    _reconcile = () => {
       
        this.showConfirmAlert("Confirm", "Execute Reconcilliation? This is generally an operation you should only perform when closing out your facility for an extended " +
                              "period of time. This operation will delete all transactions, except pending transactions. Make sure you have downloaded and archived " +
                              "transactions for your records. All tickets without balances will also be deleted. All open redeemables will be cleared, so be " +
                              "sure you no longer want customers to redeem any products. Please read the detailed instructions for this operation to make sure you " +
                              "understand the impact to the Marketplace. You probably want to disable all purchasing before executing this operation.",
                              'black', "Cancel", () => this._doReconcileConfirm(), "Reconcile", 'red');   

    }
    
    _doReconcileConfirm = () => {
        this.setState({confirmReconcilePopoverOpen: true});
    }
    
    _reconcileTextEntryConfirm = (response) => {
  
        this.setState({confirmReconcilePopoverOpen: false});
        
        if (response.toLowerCase() !== "reconcile") {
            this.showConfirmAlert("Error", "You did not type the word correctly", 'red');
            return;
        }
        
        this.incrementBusy();
        this.secureJSONFetch("/ppcs/databases/" + PP.selectedDatabase + "/marketplace/transactions/reconcile",
                             {method: "POST"}, 
                             this._reconcileSuccessful, this._fetchErrorCallback);   
            
    }
     
     
     _reconcileSuccessful = () => {
        this.showConfirmAlert("Success", "Reconcile completed", 'green');
        this.decrementBusy();
    }
    

    
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
    
    
    
    _publicChanged = (event) => {
        this.setState({publicList: event.target.checked});
    }
    
    _attendantPurchaseChanged = (event) => {
        this.setState({attendantAssistedPurchase: event.target.checked});
    }
    
    _purchaseOnChanged = (event) => {
        this.setState({purchaseOn: event.target.checked});        
    }
    
    _canDepositChanged = (event) => {
        const enabled = event.target.checked;          
        this.setState({canDeposit: enabled}); 
        if (enabled)
            this.setState({ticketEnabled: true});   //have to have tickets if we have deposit  
    }

    _serviceFeeChanged = (event) => {
        const enabled = event.target.checked;
        this.setState({enableServiceFee: enabled});
    }

    _matchingServiceFeeChanged = (event) => {
        const enabled = event.target.checked;
        this.setState({enableMatchingAGSServiceFee: enabled});
    }
    
    _marketEmailFieldChanged = (event) => {
        this.setState({marketEmail: event.target.value});
    }
    
    _marketURLFieldChanged = (event) => {
        this.setState({marketURL: event.target.value});
    }

    _termsAndConditionsFieldChanged = (event) => {
        this.setState({termsAndConditions: event.target.value});
    }
    
    _ticketEnabledChanged = (event) => {
        const enabled = event.target.checked;          
        this.setState({ticketEnabled: enabled});   
        if (!enabled) {   //disable deposit if we aren't going to use tickets
            this.setState({canDeposit: false});
            this.showConfirmAlert("Notice", "Only disable Tickets if the all the Marketplace products are automatically redeemed after purchase, such as a product that adds a guest pass to the Membership" , 'black');
        }
    }
    
    _minDepositValueChanged = () => {
        
        let newVal = parseFloat(this.state.minDeposit);  //invalid string returns NaN
        if (!newVal || newVal < MIN_DEPOSIT_AMOUNT)
            newVal = MIN_DEPOSIT_AMOUNT;  
        
        this.setState({minDeposit: newVal, minDepositValue: Currency.round(newVal)});
    }

    _serviceFeePercentChanged = () => {
        let newVal = parseFloat(this.state.serviceFeePercent);  //invalid string returns NaN
        if (!newVal || newVal < 0)
            newVal = 0;  
        
        this.setState({serviceFeePercent: newVal});
    }

    _serviceFeeFixedAmountChanged = () => {
        let newVal = parseFloat(this.state.serviceFeeFixedAmount);  //invalid string returns NaN
        if (!newVal || newVal < 0)
            newVal = 0;  
        
        this.setState({serviceFeeFixedAmount: newVal});
    }
    
    render() {
        
        const marketplaceURL = "https://" + window.location.hostname + ":" + window.location.port + "/patron?facility=" + PP.selectedDatabase;
        
        return (
            <div>
                {this.getConfirmAlertComponent()}
                
                <TextEntryPopover isOpen={this.state.confirmReconcilePopoverOpen} showSkip={false} multiline={false} title='Confirm reconcile by typing the word "reconcile" below'
                                 okCallback={this._reconcileTextEntryConfirm} cancelCallback={() => this.setState({confirmReconcilePopoverOpen: false})}/>
    
    
                <div style={{marginLeft: 'auto', marginRight: 'auto', border: '1px solid gray', padding: 20}}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            
                            <div>
                                <Tooltip title="When checked, Members can make purchases in the online Marketplace"> 
                                    <FormControl component="fieldset" >  
                                    <FormControlLabel control={<Checkbox checked={this.state.purchaseOn} onChange={this._purchaseOnChanged} color="primary"/>} label="Enable Patron Purchasing"/>
                                    </FormControl>
                                </Tooltip>
                            </div>
                            
                            <div>
                                <Tooltip title="When selected: after checkout, Patron is provided with a Marketplace Ticket (and an email with that Ticket) they present to the community. 
                                                When unchecked, the patron is not provided a ticket. It is recommended to enable this unless you are only selling Virtual Guest Passes">         
                                    <FormControl component="fieldset" >  
                                    <FormControlLabel disabled={this.state.groupToEdit === null || !this.state.purchaseOn}
                                                        control={<Checkbox checked={this.state.ticketEnabled} onChange={this._ticketEnabledChanged} color="primary"/>} label="Enable Tickets"/>
                                    </FormControl>
                                </Tooltip>
                            </div>

                            <div>
                                <Tooltip title="When selected, Patrons may purchase Products on their Ticket from an attendant on the Marketplace Purchase tab. Check this box if you are operating a concession stand and patrons use funds to purchase products.">         
                                    <FormControl component="fieldset" >  
                                    <FormControlLabel disabled={this.state.groupToEdit === null || !this.state.purchaseOn}
                                                        control={<Checkbox checked={this.state.attendantAssistedPurchase} onChange={this._attendantPurchaseChanged} color="primary"/>} label="Enable Attendant's Purchase Tab"/>
                                    </FormControl>
                                </Tooltip>
                            </div>


                            <div style={{display: 'flex', alignItems: 'center', gap: 10, marginTop: 10}}>
                                <Tooltip title="Allow Patrons to deposit funds on a Ticket. Check this box if you are operating a concession stand and patrons use funds to purchase products. Uncheck this box if you only sell redeemable products such as guest passes.">         
                                    <FormControl component="fieldset" >  
                                    <FormControlLabel disabled={this.state.groupToEdit === null || !this.state.purchaseOn}
                                                        control={<Checkbox checked={this.state.canDeposit} onChange={this._canDepositChanged} color="primary"/>} label="Enable Funds"/>
                                    </FormControl>
                                </Tooltip>
                           
                                <Tooltip title="The minimum amount of funds a Patron can deposit on a Ticket">         
                                    <TextField size="small" label={"Mininum Deposit (" + PP.currency + ")"}  variant="outlined" type="number"
                                            onChange={(event) => {this.setState({minDeposit: event.target.value});}}
                                            onKeyDown={(event) => { if (event.key === 'Enter')   //enter key releases focus
                                                                        event.target.blur();  
                                                                    }}
                                            onBlur={this._minDepositValueChanged}
                                            value={this.state.minDeposit} 
                                            style={{marginTop: 10, marginBottom: 10, maxWidth: 200}}
                                            inputProps={{readOnly: false, style: {textAlign: 'left'}, min: 0}} 
                                            InputLabelProps={{ shrink: true}} />
                                </Tooltip>
                            </div>

                            <div style={{display: 'flex', alignItems: 'center', gap: 10, marginTop: 10}}>

                                <Tooltip title="Add a service fee to a Patron's order to cover any of your own costs. The fee is not applied on free items. Note: you cannot refund this service fee to the Patron.">         
                                    <FormControl component="fieldset">  
                                    <FormControlLabel disabled={this.state.groupToEdit === null || !this.state.purchaseOn}
                                                        control={<Checkbox checked={this.state.enableServiceFee} onChange={this._serviceFeeChanged} color="primary"/>} label="Add Service Fee (non-refundable)"/>
                                    </FormControl>
                                </Tooltip>
                           
                                <Tooltip title="A percentage of the total Order cost, after all taxes and discounts have been applied">         
                                    <TextField size="small" label="Percent" variant="outlined" type="number"
                                            onChange={(event) => {this.setState({serviceFeePercent: event.target.value});}}
                                            onKeyDown={(event) => { if (event.key === 'Enter')   //enter key releases focus
                                                                        event.target.blur();  
                                                                    }}
                                            onBlur={this._serviceFeePercentChanged}
                                            value={this.state.serviceFeePercent} 
                                            style={{marginTop: 10, marginBottom: 10, maxWidth: 200}}
                                            inputProps={{readOnly: false, style: {textAlign: 'left'}, min: 0}} 
                                            InputLabelProps={{ shrink: true}} />
                                </Tooltip>

                                <Tooltip title="An additional fixed amount per Order, after the percentage is calculated">         
                                    <TextField size="small" label={"Fixed Fee (" + PP.currency + ")"} variant="outlined" type="number"
                                            onChange={(event) => {this.setState({serviceFeeFixedAmount: event.target.value});}}
                                            onKeyDown={(event) => { if (event.key === 'Enter')   //enter key releases focus
                                                                        event.target.blur();  
                                                                    }}
                                            onBlur={this._serviceFeeFixedAmountChanged}
                                            value={this.state.serviceFeeFixedAmount} 
                                            style={{marginTop: 10, marginBottom: 10, maxWidth: 200}}
                                            inputProps={{readOnly: false, style: {textAlign: 'left'}, min: 0}} 
                                            InputLabelProps={{ shrink: true}} />
                                </Tooltip>
                            </div>

                            <div style={{marginTop: 10}}>
                                <Tooltip title="Add an additional Service fee that matches your AGS payment processing fee, effectively passing our fee to you on to your Patrons. Only available if your Marketplace operates in USD.
                                                This fee is applied after your own Service fee. The fee is not applied if the order total is zero. Note: you cannot refund this service fee to the Patron.">         
                                    <FormControl component="fieldset" >  
                                    <FormControlLabel disabled={this.state.groupToEdit === null || !this.state.purchaseOn || PP.currency !== 'USD'}
                                                        control={<Checkbox checked={this.state.enableMatchingAGSServiceFee} onChange={this._matchingServiceFeeChanged} color="primary"/>} label="Add Matching AGS Service Fee (non refundable)"/>
                                    </FormControl>
                                </Tooltip>
                            </div>
                        

                            {/*  Hiding this
                            <div>
                                <Tooltip title="Make the Community Marketplace listed and searchable on the public Patron Portal (otherwise must use direct link to reach)"> 
                                    <FormControl component="fieldset" >  
                                    <FormControlLabel  control={<Checkbox checked={this.state.publicList} onChange={this._publicChanged} color="primary"/>} label="Public List"/>
                                    </FormControl>
                                </Tooltip> 
                            </div>

                            */
                            null }


                            
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Tooltip title="Show Patrons a popup window at checkout with these terms and conditions. They must press the Agree button to proceed with purchase. Leave blank to suppress the popup.">         

                                <TextField label="Terms and Conditions" variant="outlined" multiline rows={4} fullWidth InputLabelProps={{ shrink: true }}
                                           value={this.state.termsAndConditions} onChange={this._termsAndConditionsFieldChanged} inputProps={{maxLength: 254}} />
                            </Tooltip>  

                            <Tooltip title="Reply-to Email set on Marketplace emails to Patrons">         

                                <TextField label="Patron Reply-to Email" style={{maxWidth: 300, marginTop: 15, display: 'block'}} fullWidth value={this.state.marketEmail}
                                        onChange={this._marketEmailFieldChanged}
                                        InputLabelProps={{ shrink: true }} />           
                            </Tooltip>
                            
                            
                            <Tooltip title="Enter your URL linking to your Community homepage / marketplace information">         

                                <TextField label="Community Homepage URL" style={{maxWidth: 400, marginTop: 15, display: 'block'}} fullWidth value={this.state.marketURL}
                                        onChange={this._marketURLFieldChanged}
                                        InputLabelProps={{ shrink: true }} />           
                            </Tooltip>

                            
                            <div style={{display: 'flex', gap: 10, marginTop: 20}}>
                                Your Marketplace URL: 
                                <a target="_blank" rel="noopener noreferrer" href={marketplaceURL}>{marketplaceURL}</a>
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 40}}>
                        <Button fullWidth onClick={this._applySettings} variant="outlined" style={{maxWidth: 300, color: 'green'}} component="label" >
                            Apply Changes
                        </Button>
                    </div>
                </div>

                <div style={{marginTop: 20}}>

                    <Tooltip title="Clear all transactions and delete tickets without balances">
                        <Button fullWidth onClick={this._reconcile} variant="outlined" color='secondary' style={{marginLeft: 10, marginTop: 20, maxWidth: 300}} component="label" startIcon={<LineWeightIcon />}>
                            Reconcile
                        </Button>
                    </Tooltip>
                </div>
                
                {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 20}) : (<div style={{paddingBottom: 60}}/>)}

                        
            </div>

        );

    }
}


export default withCookies(SettingsTab);

