import React from 'react';
import { Typography, Button, Container } from '@material-ui/core'

import { ThemeColors } from '../Theme'

//The welcome page displays when there is no logged in user. It is informational in nature only.     
export function WelcomePage(props) {


    return (
            
        <Container style={{paddingTop: 100}}>
            <Typography variant="h4" style={{textAlign: 'center', paddingBottom: 10}}>Welcome to the Membership Web Portal</Typography>
            <Typography variant="h5" style={{textAlign: 'center', paddingBottom: 10}}>Please login to manage your facility</Typography>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20, marginTop: 20}}>
                <Button onClick={() => props.loginFunc("okta")} variant='contained' style={{color: 'white', width: 300, backgroundColor: ThemeColors.appBarBackground}}>Login to the Portal</Button>
                {/**<Button onClick={() => props.loginFunc("cognito")} variant='contained' style={{color: 'white', width: 300, backgroundColor: ThemeColors.appBarBackground}} >Login with Cognito</Button>*/}
            </div>
    
        </Container>
    
    );
    
    
}



