import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import CheckIcon from '@material-ui/icons/Check';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ScreenLockPortraitIcon from '@material-ui/icons/ScreenLockPortrait';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import TodayIcon from '@material-ui/icons/Today';

import { PopupMenu, Permissions, User } from 'react-frontend-utils'
import { PP } from '../models/PP'
import { Pages } from '../routes/Home'


/**
 *  The MainMenu component provides a dropdown menu for use in the AppBar. The selections are based on the available choices
 *  in the Pages object in Home. 
 *  
 *  The parent component MUST pass in a prop called selectPageCallback which is a function that takes a parameter which is one of
 *  a Pages object.  The callback is invoked with the user selects one of the menu items.
 * 
 *  The parent component CAN set a prop called currentPage which is one of the Pages items indicating the currently displayed page.
 *  This will show in the menu as the selected item. If nothing is selected
 * 
 */


export function MainMenu(props) {
    
    const gotoApplicationPortal = () => {
        window.location.href = "https://applications.accessgrantedsystems.net";
    };

    const gotoPassesPortal = () => {
        window.location.href = "https://passes.accessgrantedsystems.net";
    };  
    
    const gotoAccountsPortal = () => {
        window.location.href = "https://accounts.accessgrantedsystems.net";        
    };
    
    const gotoHelpPortal = () => {
        window.location.href = "https://support.accessgrantedsystems.com/portal";        
    };

    const gotoBookingPortal = () => {
        window.location.href = "https://booking.accessgrantedsystems.net";
    };

    const menuIcon = ( <MenuIcon /> );
    const checkIcon = (<CheckIcon fontSize="small"/>);
    const cloudIcon = (<CloudQueueIcon fontSize="small"/>);
    const peopleIcon = (<PeopleIcon fontSize="small"/>);
    const accountIcon = (<AccountCircleIcon fontSize="small"/>);
    const helpIcon = (<HelpOutlineIcon fontSize="small"/>);
    const manualIcon = (<MenuBookIcon fontSize="small"/>);
    const settingsIcon = (<SettingsIcon fontSize="small"/>);
    const appsIcon = (<ScreenLockPortraitIcon fontSize="small"/>);
    const marketplaceIcon = (<ShoppingCartIcon fontSize="small"/>);
    const billingIcon = (<MonetizationOnOutlinedIcon fontSize="small"/>);
    const financialsIcon = (<AccountBalanceIcon fontSize="small"/>);
    const applicationsIcon = (<AssignmentIcon fontSize="small"/>);
    const passesIcon = (<AccountBoxIcon fontSize="small"/>);
    
    const items = (() => {
        
        let menuItems = [];
        
        //---------------------- STANDARD POOL PASS ITEMS----------------------------------

        
        menuItems.push({label: "Access Database", 
                        icon: checkIcon, 
                        isSelected: (props.currentPage === Pages.POOLPASS), 
                        selectCallback: () => props.selectPageCallback(Pages.POOLPASS)}
                       );
        
        if (PP.user.databases.length > 1) {
            menuItems.push({label: "Select Database", 
                            icon: cloudIcon, 
                            isSelected: (props.currentPage === Pages.SWITCH_DATABASE),
                            selectCallback: () => props.selectPageCallback(Pages.SWITCH_DATABASE)}
                            );
        }
        
        menuItems.push({label: "Preferences", 
                        icon: settingsIcon, 
                        isSelected: (props.currentPage === Pages.PREFERENCES), 
                        selectCallback: () => props.selectPageCallback(Pages.PREFERENCES)}
                       );
               
        menuItems.push(null);
        
        //---------------------------- ADVANCED POOLPASS ------------------------------------------
         
        let addDivider = false;
        if (PP.user.isAdministrator() || PP.user.hasPermissionTo(Permissions.CREATE_USERS)) {
            menuItems.push({label: "Manage Users", 
                            icon: peopleIcon, 
                            isSelected: (props.currentPage === Pages.MANAGE_USERS), 
                            selectCallback: () => props.selectPageCallback(Pages.MANAGE_USERS)}
                           );
            addDivider = true;
        }

        if (PP.user.isAdministrator() || PP.user.role === User.Role.AGENT) {

            menuItems.push({label: "Manage Apps", 
                            icon: appsIcon, 
                            isSelected: (props.currentPage === Pages.MANAGE_APPS), 
                            selectCallback: () => props.selectPageCallback(Pages.MANAGE_APPS)}
                           );
            addDivider = true;
        }
        
        
        if (PP.user.hasPermissionTo(Permissions.SIMPLE_MARKETPLACE_MANAGE)) {

            menuItems.push({label: "Manage Marketplace", 
                    icon: marketplaceIcon, 
                    isSelected: (props.currentPage === Pages.MANAGE_MARKETPLACE), 
                    selectCallback: () => props.selectPageCallback(Pages.MANAGE_MARKETPLACE)}
                   );
           
            addDivider = true;
        }
        
        if (PP.user.hasPermissionTo(Permissions.VIEW_INVOICES)) {

            menuItems.push({label: "Membership Billing", 
                    icon: billingIcon, 
                    isSelected: (props.currentPage === Pages.BILLING), 
                    selectCallback: () => props.selectPageCallback(Pages.BILLING)}
                   );
            addDivider = true;
                   
        }
        if (addDivider)
            menuItems.push(null);
        
        
        //---------------------------- OTHER PORTALS ------------------------------------------

        
        addDivider = false;
        if (PP.user.hasPermissionTo(Permissions.PROCESS_APPLICATIONS)) {
            menuItems.push({label: "Manage Applications", 
                            icon: applicationsIcon, 
                            isSelected: false, 
                            selectCallback: gotoApplicationPortal}
                           );
            addDivider = true;
        }
        
        if (PP.user.hasPermissionTo(Permissions.VIEW_PASSES)) {
                           
            menuItems.push({label: "Manage Passes", 
                        icon: passesIcon, 
                        isSelected: false, 
                        selectCallback: gotoPassesPortal}
                       );
            addDivider = true;            
        }

        if (PP.user.hasPermissionTo(Permissions.VIEW_BOOKING)) {

            menuItems.push({label: "Manage Booking",
                            icon: <TodayIcon fontSize="small"/>, 
                            isSelected: false,
                            selectCallback: gotoBookingPortal
                            });
        }
        
        if (PP.user.hasPermissionTo(Permissions.VIEW_ACCOUNT_PORTAL)) {
    
            menuItems.push({label: "Manage Account", 
                            icon: financialsIcon, 
                            isSelected: false, 
                            selectCallback: gotoAccountsPortal}
                           );        
                     
        }
        
        if (addDivider)
            menuItems.push(null);

              
        //---------------------------- SUPERADMIN ONLY ------------------------------------------

        
        if (PP.user.isSuperAdmin()) {
            menuItems.push(null);
         
            menuItems.push({label: "Financials", 
                  icon: financialsIcon, 
                  isSelected: (props.currentPage === Pages.FINANCIALS), 
                  selectCallback: () => props.selectPageCallback(Pages.FINANCIALS)}
                 );         
         
            menuItems.push(null);
        }
        
        
        //---------------------------- EVERYONE ------------------------------------------


        menuItems.push({label: "Profile", 
                        icon: accountIcon, 
                        isSelected: (props.currentPage === Pages.PROFILE), 
                        selectCallback: () => props.selectPageCallback(Pages.PROFILE)}
                       );
               
        menuItems.push({label: "Help", 
                        icon: helpIcon, 
                        isSelected: false, 
                        selectCallback: gotoHelpPortal}
                       );
               
        menuItems.push({label: "Online Manual", 
                        icon: manualIcon, 
                        isSelected: (props.currentPage === Pages.HELP), 
                        selectCallback: () => props.selectPageCallback(Pages.HELP)}
                       );
        
        return menuItems;
    })();

    return (

        <PopupMenu menuIcon={menuIcon} menuItems={items} lighterHover={true}/>
         
    );
    
   
}

