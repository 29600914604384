import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, Typography, Container, Checkbox, Button, Collapse, IconButton, Tooltip } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AssignmentIcon from '@material-ui/icons/Assignment';

import AGTheme, { ThemeColors } from '../Theme'
import EmailTermsOfService from '../components/EmailTermsOfService'
import { RestComponent, User, HomepageContext, RichTextEditor, plaintextToRichTextJson, draftContentToHtml, draftContentToPlaintext, ManageTextField, Email } from 'react-frontend-utils'
import { logo } from '../utils/Image'



class EmailComposer extends RestComponent {
  
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        },
        mainTitle: {
            textShadow: "2px 2px #333333",
            fontWeight: "bold",
            fontSize: "160%",
            textAlign: 'right',
            flexGrow: 1   //fill all space to push other elements to the right edge
         }
    }

    _toEmailRef = React.createRef();
    _ccEmailRef = React.createRef();
   
    constructor(props) {
        super(props);
        this.state.currentUser = null;
        this.state.serverError = null;
        this.state.fromName = "";
        this.state.replyToEmail = "";
        this.state.toEmails = "";
        this.state.ccEmails = "";
        this.state.blind = true;
        this.state.subject = "";

        const blankText = "\n".repeat(6); // Create blank lines
        this.state.body = plaintextToRichTextJson(blankText);

        this.state.revealTerms = false;
    }

    componentDidMount() {
        super.componentDidMount(); 
        this._fetchCurrentUser();        
    }

    //Needed to pull in user auth/cookies
    _fetchCurrentUser = () => {
        this.secureJSONFetch("/auth/currentUser", {}, this._checkUserCallback); 
    }


    /**
     * Callback to be executed from fetching the current user
     * @param {Object} response null if there is no current authenticated user, or a JSON object describing the user
     */
    _checkUserCallback = (response) => {
        if (!response)
            this.setState({serverError: "Not logged in. Please close this tab and log into the portal again."});
        else {
            const user = new User(response);
            this.setState({currentUser: user, fromName: user.name(), replyToEmail: user.email});  //create the global user
        }
    }


    _pasteEmailTOButtonClicked = () => {
        navigator.clipboard.readText().then(text => {
            this.setState({toEmails: text});
            if (this._toEmailRef.current)
                this._toEmailRef.current.set(text);
        });
    }

    _pasteEmailCCButtonClicked = () => {
        navigator.clipboard.readText().then(text => {
            this.setState({ccEmails: text});
            if (this._ccEmailRef.current)
                this._ccEmailRef.current.set(text);
        });
    }

    _sendEmail = () => {
        // Validate the email fields, collect all email addresses from all fields into an array (split by semicolons and commas
        let toEmails = this.state.toEmails.split(/[;,]/).map(e => e.trim()).filter(e => e.length > 0);
        let ccEmails = this.state.ccEmails.split(/[;,]/).map(e => e.trim()).filter(e => e.length > 0);
        
        let allEmails = toEmails.concat(ccEmails);

        if (allEmails.length === 0) {
            this.showConfirmAlert("Error", "You must provide at least one email address in the 'To' or 'CC' fields to send an email", 'red');
            return;
        }

        if (this.state.fromName.trim().length === 0) {
            this.showConfirmAlert("Error", "The 'From Name' field cannot be empty", 'red');
            return;
        }

        if (!Email.validateEmail(this.state.replyToEmail)) {
            this.showConfirmAlert("Error", "The reply-to email address '" + this.state.replyToEmail + "' is invalid.", 'red');
            return;
        }

        for (let email of allEmails) {
            if (!Email.validateEmail(email)) {
                this.showConfirmAlert("Error", "The receipient email address '" + email + "' is invalid.", 'red');
                return;
            }
        }

        if (this.state.subject.trim().length === 0) {
            this.showConfirmAlert("Warning", "Do you really want to send the email without subject?", 'black', "Cancel", () => this._continueSend(allEmails.length), "Send Anyway", 'red');  
            return;
        }
        else
            this._continueSend(allEmails.length);

    }


    _continueSend = (emailCount) => {
        this.showConfirmAlert("Confirm", "Ready to send this email to " + emailCount + " recipient" + (emailCount > 1 ? "s?" : "?"), 'black', "Cancel", this._sendEmailNow, "Send", 'green');
    }


    _sendEmailNow = () => {

        const email = {
            fromName: this.state.fromName.trim(),
            replyToEmail: this.state.replyToEmail.trim(),
            toEmails: this.state.toEmails,
            ccEmails: this.state.ccEmails,
            blind: this.state.blind,
            subject: this.state.subject,
            bodyPlaintext: draftContentToPlaintext(this.state.body),
            bodyHtml: draftContentToHtml(this.state.body)
        };

        this.secureJSONFetch("/ppcs/sendEmail", {method: "POST", body: JSON.stringify(email)}, 
                            this._sendSuccess, this._fetchErrorCallback);

    }

    _sendSuccess = (response) => {
        this.showConfirmAlert("Success", "Email sent successfully", 'green');
    }

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
    }
 
    sessionExpired = () => {
        this.setState({serverError: "Session Expired. Please close this tab"});
    }



    render() {

        
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = 20;
        const revealIcon = this.state.revealTerms ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;

        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={AGTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  

                        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                                <Toolbar disableGutters={true}>
                                     {logo}
                                     <Typography variant="h5" style={this.styles.appTitle}>Membership Portal</Typography>
                                     <Typography variant="h5" style={this.styles.mainTitle}>Email Composer</Typography>                                                                          
                                </Toolbar>
                            </div>
                        </AppBar>


                        {serverErrorMessage}
                        
                        <Container maxWidth={false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                        
                            {this.state.currentUser ? 
                                <Fragment>

                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 5}}>
                                        <Typography variant="body1">{"Sending User: " + this.state.currentUser.name()}</Typography>
                                        <Button variant="contained" style={{backgroundColor: 'green', color: 'white'}} onClick={this._sendEmail}>Send Email</Button>
                                    </div>

                                    <ManageTextField tooltip='The name that appears in the "From" field of the email'
                                                    autoAccept={true} fullWidth={true} justify='left' label="From Name"                           
                                                    json="fromName"
                                                    initialValue={this.state.fromName}
                                                    style={{marginTop: 15, maxWidth: 400}}
                                                    onFieldChange={(json, val) => this.setState({fromName: val})} changedBackgroundColor='white'/>

                                    <ManageTextField tooltip='The reply-to email address'
                                                    autoAccept={true} fullWidth={true} justify='left' label="Reply-to Email Address"                           
                                                    json="replyToEmail"
                                                    initialValue={this.state.replyToEmail}
                                                    style={{marginTop: 15, maxWidth: 400}}
                                                    onFieldChange={(json, val) => this.setState({replyToEmail: val})} changedBackgroundColor='white'/>

                                    <div style={{width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 15}} >   

                                        <ManageTextField tooltip='Enter "to" email addresses separated by semicolons'
                                                        autoAccept={true} fullWidth={true} justify='left' label="To Email Addresses"                           
                                                        json="toEmails"
                                                        ref={this._toEmailRef}
                                                        multiline={4}
                                                        onFieldChange={(json, val) => this.setState({toEmails: val})} changedBackgroundColor='white'/>

                                        <Tooltip title="Paste emails from clipboard" >
                                            <IconButton edge="start" onClick={this._pasteEmailTOButtonClicked} style={{...this.props.style, marginLeft: 4}}>
                                                <AssignmentIcon style={{color: ThemeColors.mailColor}} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <div style={{width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 15}} >   

                                        <ManageTextField tooltip='Enter "cc" email addresses separated by semicolons'
                                                        autoAccept={true} fullWidth={true} justify='left' label="CC Email Addresses"                           
                                                        json="ccEmails"
                                                        ref={this._ccEmailRef}
                                                        onFieldChange={(json, val) => this.setState({ccEmails: val})} changedBackgroundColor='white'/>

                                        <Tooltip title="Paste emails from clipboard" >
                                            <IconButton edge="start" onClick={this._pasteEmailCCButtonClicked} style={{...this.props.style, marginLeft: 4}}>
                                                <AssignmentIcon style={{color: ThemeColors.mailColor}} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                                        <Checkbox checked={this.state.blind} color='primary' onChange={(event) => this.setState({blind: event.target.checked})}/>
                                        <Typography variant="body1">Hide all receipient email addresses (blind send)</Typography>
                                    </div>

                                    <ManageTextField autoAccept={true} fullWidth={true} justify='left' label="Subject"                           
                                                    json="subject"
                                                    style={{marginTop: 15}}
                                                    onFieldChange={(json, val) => this.setState({subject: val})} changedBackgroundColor='white'/>

                                    <div style={{marginTop: 20, border: '1px solid lightGray', borderRadius: 5, padding: 10}}>
                                        <RichTextEditor
                                            draftContent={this.state.body}     
                                            editing={true}  
                                            onChange={ (draftJson) => {
                                                this.setState({body: draftJson});
                                            }}
                                            
                                        />
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 40}}>
                                        <IconButton edge="start" onClick={() => this.setState({revealTerms: !this.state.revealTerms})}>
                                            {revealIcon}                          
                                        </IconButton>      
                                    <Typography style={{marginLeft: -5, fontSize: 12}} variant="button">AGS Email Service Terms and Conditions</Typography>  
                                    </div>  
                                    <Collapse in={this.state.revealTerms}>
                                        <EmailTermsOfService/>
                                    </Collapse>


                                </Fragment>
                                : null
                            }
                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(withRouter(EmailComposer));


